<template>
  <div class="pa-3">
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column">
        <h2 v-text="internalName"></h2>
        <p class="my-0">Hoy es <strong v-text="dateCurrentFormat"></strong></p>
      </div>
    </div>
    <div
      class="d-flex justify-center"
      v-if="Object.keys(activitiesDays).length"
    >
      <div class="col-sm-12 col-md-5 col-lg-3">
        <v-tabs
          background-color="bg-transparent"
          v-model="tab"
          align-with-title
          height="80"
          class="mb-3"
          show-arrows
        >
          <v-tab
            v-for="(activity, item, i) in activitiesDays"
            :key="i"
            class="rounded-lg elevation-3"
          >
            <div class="d-flex flex-column" id="item-tab">
              <span v-text="getDayWeek(item)" class="text-center"></span>
              <span
                v-text="getDayMonth(item)"
                class="text-center font-weight-bold"
              ></span>
              <span v-text="getMonth(item)"></span>
            </div>
          </v-tab>
        </v-tabs>
        <div class="row">
          <div class="col-sm-12">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(day, item, i) in activitiesDays" :key="i">
                <v-card
                  class="py-2 px-3 mb-3 black--text card-border rounded-lg"
                  v-for="(activity, x) in day"
                  :key="x"
                  elevation="3"
                >
                  <div class="d-flex flex-column">
                    <span
                      v-text="activity.name"
                      class="w-100 font-weight-bold"
                    ></span>
                    <span
                      class="w-100"
                      v-text="getTimeInterval(item, x)"
                    ></span>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-border {
  border-left: 10px !important;
  border-color: $secondary-color !important;
  border-style: solid !important;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  /* display: initial; */
  visibility: hidden;
}
.v-tabs >>> .v-slide-group__prev,
.v-tabs >>> .v-slide-group__next {
  flex: none !important;
  min-width: 30px;
}

.v-tab {
  background-color: #f5f0f0;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  color: black;
  min-width: 55px;
  max-width: 360px;
}

.v-tabs >>> .v-slide-group__content {
  padding-top: 5px;
  padding-bottom: 5px;
}

.v-tab:nth-child(2n + 1) {
  margin-left: 15px;
  margin-right: 15px;
}

.v-tabs >>> .v-tabs-slider {
  height: 0% !important;
  width: 0% !important;
}

#item-tab > span:nth-child(2) {
  color: black;
}

.v-tab--active:hover,
.v-tab--active {
  color: white !important;
  background-color: $secondary-color;
}
.v-tabs-items >>> .v-window-item--active {
  height: 100% !important;
}
</style>

<script>
import moment from "moment";
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "GeneralProgramming",
  mixins: [loaderMixin, notificationMixin],
  data() {
    return {
      activitiesDays: {},
      tab: null,
    };
  },
  methods: {
    redirectToFair() {
      this.$router.push({ name: "fair" });
    },
    getDayWeek(date) {
      return moment(date).format("ddd").substring(0, 3);
    },
    getDayMonth(value) {
      return moment(value).format("D");
    },
    getMonth(value) {
      return moment(value).format("MMMM").substring(0, 3);
    },
    getTimeInterval(day, index) {
      let initialHour = moment(
        this.activitiesDays[day][index].initialHour,
        "H:m"
      ).format("hh:mm A");
      let finalHour = moment(
        this.activitiesDays[day][index].finalHour,
        "H:m"
      ).format("hh:mm A");
      if (this.activitiesDays[day]) {
        return `${initialHour} - ${finalHour}`;
      } else {
        return "";
      }
    },
    getActivities() {
      this.$axiosApiMobile
        .get(`/generalActivities?fairId=${this.fair.id}`)
        .then((res) => {
          this.activitiesDays = {};
          res.data.forEach((activity) => {
            activity.activity_dates.forEach((date) => {
              if (!this.activitiesDays[date.date]) {
                this.activitiesDays[date.date] = [];
              }
              this.activitiesDays[date.date].push({
                order: activity.order,
                name: activity.name,
                initialHour: date.initialHour,
                finalHour: date.finalHour,
              });
            });
          });
          this.hideLoader();
        })
        .catch((error) => {
          this.errorHandler(error?.response?.status);
          this.hideLoader();
        });
    },
  },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
    dateCurrentFormat() {
      return moment().format("dddd, D ## MMMM").replace("##", "de");
    },
    internalName() {
      return (
        this.fair?.app_modules?.filter((item) => item.modules == "PRGEN")[0]
          ?.internalName ?? "Programación General"
      );
    },
  },
  created() {
    moment.locale("es");
    this.getActivities();
    this.showLoader();
  },
};
</script>
